import React from "react";
import PropTypes from "prop-types";
import { Image } from "../styled-components";

const Metadata = ({ context }) => {
  const { title, description } = context;
  const snappy_logo =
    "https://snappy-general-purpose-images.s3-us-west-1.amazonaws.com/snappy-gala-header-narrow.svg";

  return (
    <div className="campaign__vote__main__metadata">
      <Image src={snappy_logo} />
      <div
        className="campaign__vote__main__metadata__text"
        style={{
          textAlign: "center",
          marginTop: "30px",
          paddingRight: "10%",
          paddingLeft: "10%",
          background: "#ffffff"
        }}
      >
        <h1>{title}</h1>
        <div>
          {description?.split("\n").map((text, key) => {
            return <div key={key}>{text}</div>;
          })}
        </div>
      </div>
    </div>
  );
};

Metadata.propTypes = {
  context: PropTypes.object.isRequired
};

export default Metadata;
