import React from "react";
import FormBuilder from "../FormBuilder";
import Metadata from "../Metadata";
import { FlexColumnLayout, CampaignContainer } from "../styled-components";

const Campaign = ({ context, fields, onSubmit, id, mode }) => (
  <div style={{ background: "#e5e5e5" }}>
    <FlexColumnLayout className="campaign__vote">
      <CampaignContainer className="campaign__vote__main">
        <Metadata context={context} />
        <div
          className="campaign__vote__main__form"
          style={{
            padding: "0 10% 5% 10%",
            background: "#ffffff"
          }}
        >
          <FormBuilder
            formJson={fields}
            campaignId={id}
            onSubmit={onSubmit}
            mode={mode}
          />
        </div>
      </CampaignContainer>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        Powered by Snappy
      </div>
    </FlexColumnLayout>
  </div>
);

export default Campaign;
