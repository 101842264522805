import styled, { createGlobalStyle } from 'styled-components';
import { Button, Spin, Select, Form } from 'antd';
import media from './media';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: "azo-sans-web", sans-serif;
    margin: 0;
    padding: 0;
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #29abe2;
    color: #fff;
    border: none;

    height: 40px;
    line-height: 40px;
    font-size: 18px;

    @media ${media.mobileS} {
      width: 100%;
    }
    @media ${media.tablet}, ${media.laptop} {
      width: 300px;
    }
  }
`;

export const Centered = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
`;

export const CenterParagraph = styled.div`
  text-align: center;

  @media ${media.mobileS} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${media.tablet} {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const ParagraphContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(100vh - 85%);
  right: 0;
  left: 0;

  @media ${media.mobileS} {
    button.ant-btn {
      margin: 15px auto;
    }
  }

  @media ${media.tablet} {
    button.ant-btn {
      margin: 35px auto;
    }
  }
`;

export const Spinner = styled(Spin)`
  && {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledSelect = styled(Select)`
  &&& {
    .ant-select-arrow-icon svg {
      transform: none;
    }
  }
`;

export const FlexColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${media.mobileS} {
    width: 100%;
    // height: 100vh;
  }

  @media ${media.tablet} {
    width: 100%;
    height: 100%;
  }
`;

export const CampaignContainer = styled.div`
  max-width: 100vw;

  @media screen and ${media.mobileS} {
    width: 100%;
    height: 100%;
    background: white;
  }

  @media screen and ${media.tablet} {
    width: 800px;
    height: 100%;
    background: white;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    text-align: start;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  @media screen and ${media.tabletMaxWidth} {
    .ant-form-item-label {
      white-space: normal;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
`;
