import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Spin, Icon, Tooltip } from 'antd';
import * as antd from 'antd';
import get from 'lodash/get';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import { PrimaryButton, StyledSelect, FormItem } from '../styled-components';
import { searchNominees } from '../../services';
import { addRequiredMessageToProps, notifyError } from '../../utils';

const FakeEmployees = ['Jane Doe', 'John Doe', 'Janie Doe', 'Johnny Doe'];

const FormBuilder = ({
  form,
  formJson,
  campaignId,
  mode = 'vote',
  onSubmit = noop,
}) => {
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const { validateFields, getFieldDecorator, getFieldValue } = form;

  const handleSearch = (text) => {
    if (text.trim().length < 2) return;
    setSearchLoading(true);
    searchNominees(campaignId, text)
      .then((response) => {
        const options = response.map((item) => ({
          text: item.text,
          value: item.value,
        }));
        setSearchData(options);
      })
      .catch((err) =>
        notifyError({
          message: 'Failed to search nominees',
          description: err.message,
        })
      )
      .finally(() => setSearchLoading(false));
  };

  const handleFakeSearch = (text) => {
    setSearchLoading(true);
    const filteredEmployees = FakeEmployees.filter((employee) =>
      employee.toLowerCase().includes(text.toLowerCase())
    ).map((employee) => ({
      text: employee,
      value: employee,
    }));
    setSearchData(filteredEmployees);
    setSearchLoading(false);
  };

  const getGrandChildValues = (values) => {
    let updatedValues = cloneDeep(values);
    const grandChildren = Object.keys(updatedValues).filter(
      (key) => key.indexOf('grandChild') !== -1
    );
    if (grandChildren.length === 0) return updatedValues;

    grandChildren.forEach((key) => {
      const [id, content] = key.split('-');

      if (updatedValues[key].length > 0) {
        if (Array.isArray(updatedValues[id])) {
          const index = updatedValues[id].indexOf(content);

          if (updatedValues[id][index] === content) {
            updatedValues[id][index] = updatedValues[key];
          }
        } else if (updatedValues[id] === content) {
          updatedValues[id] = updatedValues[key];
        }
      }

      updatedValues = omit(updatedValues, key);
    });

    return updatedValues;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoading(true);
          const submitValues = getGrandChildValues(values);
          await onSubmit(submitValues);
        } catch (err) {
          notifyError({
            message: 'Failed to submit form',
            description: err.message,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const getSearchableCallback = () =>
    mode === 'vote' ? debounce(handleSearch, 1000) : handleFakeSearch;

  const getFormItemProps = (props) => ({
    ...props,
    label: (
      <Tooltip placement="topLeft" title={props.label}>
        <span>{props.label}</span>
      </Tooltip>
    ),
  });

  const renderSearchable = (field, extendedDecoratorProps) => {
    const onSearch = getSearchableCallback();
    const { id, component, formItemProps } = field;
    const componentProps = {
      ...component.props,
      onFocus: () => setSearchData([]),
      notFoundContent: searchLoading ? <Spin size="small" /> : null,
      suffixIcon: <Icon type="search" />,
      placeholder: 'Search by name or email...',
      filterOption: false,
      showSearch: true,
      allowClear: true,
      onSearch,
    };

    const itemProps = getFormItemProps(formItemProps);

    return (
      <FormItem key={formItemProps.label} {...itemProps}>
        {getFieldDecorator(id, {
          ...extendedDecoratorProps,
        })(
          <StyledSelect {...componentProps}>
            {searchData.map((item) => (
              <StyledSelect.Option value={item.value} key={item.text}>
                {item.text}
              </StyledSelect.Option>
            ))}
          </StyledSelect>
        )}
      </FormItem>
    );
  };

  const renderFormItem = (field) => {
    const { id, component, decoratorProps, formItemProps } = field;
    const disabled = component?.props?.disabled;

    if (id === 'nomineeId') {
      const extendedDecoratorProps = addRequiredMessageToProps(
        disabled,
        decoratorProps
      );
      return renderSearchable(field, extendedDecoratorProps);
    }

    const Component = get(antd, component.type);

    const maxLength = component?.props?.maxLength;
    const validations = maxLength
      ? [
          {
            max: maxLength > 1000 ? 1000 : maxLength,
            message: `This field cannot be longer than ${maxLength} characters`,
          },
        ]
      : [];

    const extendedDecoratorProps = addRequiredMessageToProps(
      disabled,
      decoratorProps,
      validations
    );

    const itemProps = getFormItemProps(formItemProps);

    const getGrandChild = (childComponent) => {
      const { grandChild, content } = childComponent;
      const { type, props, content: grandChildContent } = grandChild;
      const GrandChild = get(antd, type);

      return getFieldDecorator(`${id}-${content}-grandChild`, {
        initialValue: grandChildContent,
      })(
        <GrandChild
          key={`${id}-${content}-grandChild`}
          {...props}
          disabled={
            !getFieldValue(id) || getFieldValue(id).indexOf(content) === -1
          }
        />
      );
    };

    return (
      <FormItem key={`${id}-${formItemProps.label}`} {...itemProps}>
        {getFieldDecorator(id, {
          ...extendedDecoratorProps,
        })(
          <Component {...component.props}>
            {component?.childComponents?.map((childComponent, i) => {
              const Child = get(antd, childComponent.type);

              return (
                <Child
                  key={`${id}-${childComponent.content}-${i}`}
                  value={childComponent.content}
                  {...childComponent.props}
                >
                  {childComponent.grandChild
                    ? getGrandChild(childComponent)
                    : childComponent.content}
                </Child>
              );
            })}
          </Component>
        )}
      </FormItem>
    );
  };

  const renderSubmit = () => {
    if (mode === 'preview') {
      return (
        <Row type="flex" justify="end">
          <PrimaryButton onClick={onSubmit}>Close preview</PrimaryButton>
        </Row>
      );
    }
    return (
      <Row type="flex" justify="end">
        <PrimaryButton htmlType="submit" loading={loading}>
          Submit
        </PrimaryButton>
      </Row>
    );
  };

  return (
    <Form colon={false} onSubmit={handleSubmit}>
      {formJson.map(renderFormItem)}
      {renderSubmit()}
    </Form>
  );
};

FormBuilder.propTypes = {
  form: PropTypes.object.isRequired,
  formJson: PropTypes.array.isRequired,
  campaignId: PropTypes.string,
  mode: PropTypes.oneOf(['vote', 'preview']),
  onSubmit: PropTypes.func,
};

export default Form.create({ name: 'survey' })(FormBuilder);
