import { postRequest, getRequest } from "../utils";

const NOMINATION_API = process.env.REACT_APP_NOMINATION_API;

const urls = campaignId => ({
  FORM: `${NOMINATION_API}/campaign/${campaignId}/form`,
  SEARCH: `${NOMINATION_API}/campaign/${campaignId}/nominees`,
  PREVIEW: `${NOMINATION_API}/campaign/${campaignId}/preview`
});

export function getForm(campaignId) {
  return getRequest(urls(campaignId).FORM);
}

export function submitForm(campaignId, payload) {
  return postRequest(urls(campaignId).FORM, payload);
}

export function searchNominees(campaignId, query) {
  const url = urls(campaignId).SEARCH;
  return getRequest(`${url}?q=${query}`);
}

export function previewForm(campaignId) {
  return getRequest(urls(campaignId).PREVIEW);
}
