import React from "react";
import {
  FlexColumnLayout,
  CampaignContainer,
  Image
} from "../styled-components";

const StoppedCampaign = () => {
  const image =
    "https://snappy-general-purpose-images.s3-us-west-1.amazonaws.com/p2p-nominations-closed.svg";

  return (
    <div style={{ background: "#e5e5e5", height: "100vh" }}>
      <FlexColumnLayout className="campaign_stopped">
        <CampaignContainer className="campaign_stopped__main">
          <div className="campaign_stopped__main_image">
            <Image src={image} />
            <div
              className="campaign_stopped__main__text"
              style={{
                textAlign: "center",
                marginTop: "30px",
                paddingLeft: "10%",
                paddingRight: "10%"
              }}
            >
              <h1 style={{ fontSize: "32px" }}>
                Sorry! We are no longer accepting nominations.
              </h1>
              <p style={{ fontSize: "18px" }}>
                Try contacting your HR manager if you think this is a mistake.
              </p>
            </div>
          </div>
        </CampaignContainer>
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          Powered by Snappy
        </div>
      </FlexColumnLayout>
    </div>
  );
};

export default StoppedCampaign;
