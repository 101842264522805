import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory, Redirect } from 'react-router-dom';
import isArray from 'lodash/isArray';
import { Spinner } from '../styled-components';
import Campaign from '../Campaign';
import { getForm, submitForm } from '../../services';
import { notifyError, isProduction } from "../../utils";

const CampaignVote = () => {
  const history = useHistory();
  const { id } = useParams();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const token = params.get('token');
  sessionStorage.setItem('token', token);

  const [context, setContext] = useState({});
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState(false);
  const [stoppedCampaign, setStoppedCampaign] = useState(false);

  const initFullStory = (email) => {
    window.FS.identify(email);
  };

  const onSubmit = async (values) => {
    try {
      const labels = fields
        .map((item) => ({ id: item.id, props: item.formItemProps }))
        .reduce((acc, item) => {
          acc[item.id] = item.props.label;
          return acc;
        }, {});

      const formValues = Object.entries(values).reduce((acc, [key, value]) => {
        acc[key] = {
          label: labels[key],
          value: isArray(value) ? value : [value],
        };
        return acc;
      }, {});

      await submitForm(id, { formValues });
      setIsDone(true);
    } catch (err) {
      notifyError({
        message: 'Failed to submit form',
        description: err.message,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { context, fields, nominationEndAt } = await getForm(id);
        const { email } = context ?? {};
        if (email && isProduction) initFullStory(email);
        setContext(context);
        setFields(fields);
        setStoppedCampaign(nominationEndAt);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (isDone) {
      history.push(`/campaign/${id}/success`);
    }
  }, [id, isDone, history]);

  if (loading) return <Spinner size="large" />;
  if (error) return <Redirect to={`/error/${id}`} />;
  if (stoppedCampaign) return <Redirect to={`/campaign/${id}/stopped`} />;

  return (
    <Campaign context={context} fields={fields} id={id} onSubmit={onSubmit} />
  );
};

export default CampaignVote;
