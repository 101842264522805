import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  CenterParagraph,
  ParagraphContainer,
  FlexColumnLayout,
  Image,
  PrimaryButton,
} from '../styled-components';
import media from '../styled-components/media';

const picturesBucketUrl = 'https://images.meetsnappy.com';
const large = `${picturesBucketUrl}/SurveyNominationSuccess.svg`;
const small = `${picturesBucketUrl}/Mobile_success_page.svg`;

export default () => {
  const history = useHistory();
  const { id } = useParams();
  const { search } = useLocation();
  const token = sessionStorage.getItem('token');

  const params = new URLSearchParams(search);
  const isPreview = params.get('preview');

  const onClick = () => {
    history.push(
      `/campaign/${id}/${isPreview ? 'preview' : 'form'}?token=${token}`
    );
  };

  return (
    <FlexColumnLayout>
      <div style={{ height: '100vh' }}>
        <picture>
          <source srcSet={large} media={media.tablet} />
          <source srcSet={small} media={media.mobileS} />
          <Image src={small} />
        </picture>
      </div>
      <ParagraphContainer>
        <CenterParagraph>Thank you for your nomination!</CenterParagraph>
        <CenterParagraph>We will review it.</CenterParagraph>
        {token && (
          <PrimaryButton onClick={onClick}>
            Nominate another colleague
          </PrimaryButton>
        )}
      </ParagraphContainer>
    </FlexColumnLayout>
  );
};
