import React from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as Logo } from "../../static/SurveyNominationError.svg";
import { Centered, PrimaryButton, CenterParagraph } from "../styled-components";
import { Row } from "antd";

export default () => {
  const { id } = useParams();
  const supportPage = `https://web.snappygifts.com/support/P2P-${id}`;

  return (
    <Centered>
      <Row type="flex" justify="center">
        <Logo />
      </Row>
      <div style={{ margin: "40px 0" }}>
        <CenterParagraph>Oh no!</CenterParagraph>
        <CenterParagraph> Seems like something went wrong.</CenterParagraph>
      </div>
      <Row
        type="flex"
        justify="center"
        style={{ marginLeft: "25px", marginRight: "25px" }}
      >
        <PrimaryButton href={supportPage}>Contact us</PrimaryButton>
      </Row>
    </Centered>
  );
};
