import React, { Fragment } from "react";
import CampaignPreview from "./components/CampaignPreview";
import CampaignVote from "./components/CampaignVote";
import StoppedCampaign from "./components/StoppedCampaign";
import Success from "./components/Success";
import Error from "./components/Error";
import { GlobalStyle } from "./components/styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const App = () => {
  return (
    <Fragment>
      <Router>
        <Switch>
          <Route exact path="/campaign/:id/form">
            <CampaignVote />
          </Route>
          <Route exact path="/campaign/:id/preview">
            <CampaignPreview />
          </Route>
          <Route exact path="/campaign/:id/stopped">
            <StoppedCampaign />
          </Route>
          <Route exact path="/campaign/:id/success">
            <Success />
          </Route>
          <Route exact path="/error/:id">
            <Error />
          </Route>
        </Switch>
      </Router>
      <GlobalStyle />
    </Fragment>
  );
};

export default App;
