import React, { useEffect, useState } from 'react';
import { Spinner } from '../styled-components';
import Campaign from '../Campaign';
import { previewForm } from '../../services';
import { useLocation, useParams, useHistory, Redirect } from 'react-router-dom';
import { notifyError } from '../../utils';

const CampaignPreview = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(search);
  const token = params.get('token');
  sessionStorage.setItem('token', token);

  const [context, setContext] = useState({});
  const [fields, setFields] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    history.push(`/campaign/${id}/success?preview=true`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { context, fields } = await previewForm(id);
        setContext(context);
        setFields(fields);
      } catch (err) {
        setError(true);
        notifyError({
          message: 'Failed to preview form',
          description: err.message,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  if (loading) return <Spinner size="large" />;
  if (error) return <Redirect to={`/error/${id}`} />;

  return (
    <Campaign
      context={context}
      fields={fields}
      campaignId={id}
      onSubmit={onSubmit}
      mode="preview"
    />
  );
};

export default CampaignPreview;
