import axios from "axios";
import { notification } from "antd";

const request = async ({ url, method, body }) => {
  const token = sessionStorage.getItem("token");

  try {
    const opt = {
      method,
      url,
      data: body,
      responseType: "json",
      headers: {
        Authorization: token
      }
    };

    const { data } = await axios(opt);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getRequest = async url => {
  return request({ url, method: "GET" });
};

export const postRequest = async (url, body = {}) => {
  return request({ url, method: "POST", body });
};

export const addRequiredMessageToProps = (
  disabled,
  decoratorProps,
  validations = []
) => {
  if (disabled) {
    // disabled fields should not have any validation rules. The already filled.
    return decoratorProps;
  }
  const { rules } = decoratorProps;
  const newRules = rules.map(rule => {
    if (rule.required) {
      return {
        ...rule,
        message: "This field is required"
      };
    }
    return rule;
  });

  return {
    ...decoratorProps,
    rules: [...newRules, ...validations]
  };
};

const notify = type => ({ message, description }) => {
  notification[type]({
    message,
    description,
    placement: "bottomLeft"
  });
};

export const notifyError = notify("error");

const environment = process.env.REACT_APP_ENVIRONMENT;
export const isProduction = environment === "PRODUCTION";
